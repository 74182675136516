import React, { Component } from 'react';

// CSS
import '../assets/css/Discover.css'

// Components
import Header from '../components/header/Header2';
import Section1 from '../components/discover/Section1';

class Discover extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className='discover-main'>
            {/* Header */}
            <Header />
            
            {/* SECTION 1 */}
            <Section1 />
        </div>
    );
  }
}

export default Discover;