import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// Providers
import { User } from './providers/User';

// CSS
import './Main.css'
import './App.css'

// Pages
import Home from './pages/Home';
import HowItWorks from './pages/HowItWorks2'
import CompensationPlan from './pages/CompensationPlan2'
import NFTs from './pages/NFTs'
import Tools from './pages/Tools'
import Rules from './pages/Rules'
import GetStarted from './pages/GetStarted2'
import Discover from './pages/Discover';
import WalletCompensationPlan from './pages/WalletCompensationPlan';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      logout: true,
      isHome: false
    };
  }

  componentDidMount = () => {
    if (User.hasToken()) {
        this.setState({ logout: false })
    } else {
      
    }

    if (this.constructor.name === 'HomeNew') {
      
      // Do something specific for HomeNew.js component
    }
  }

  render() {

    const {logout, isHome} = this.state

    return (
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/how-it-works" exact element={<HowItWorks />} />
          <Route path="/compensation-plan" exact element={ <CompensationPlan /> }  />
          <Route path="/nfts" exact element={ <NFTs /> } />
          <Route path="/tools" exact element={ <Tools /> } />
          <Route path="/rules" exact element={ <Rules /> } />
          <Route path="/get-started" exact element={ <GetStarted /> } />
          <Route path="/discover" exact element={ <Discover /> } />
          <Route path="/wallet-compensation-plan" exact element={ <WalletCompensationPlan /> } />
          {/* <Route path="/get-started" exact element={ User.hasToken() ? <GetStarted /> : <Navigate to="/" /> } /> */}

          {/* 404 Page */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    )
  }
}

export default App;
