import React, { Component } from 'react';

// Components
import Header from '../components/header/Header2';
import Footer from '../components/footer/Footer2';

import data from '../data/walletCompensation'

class WalletCompensationPlan extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <>
            <Header />

            {/* Section 2 */}
            <section class="first-row top-curve top-curve-7 rules-2 pt-12 tl-pt-8 tp-pt-6 m-pt-5 new-last-section">
              <div class="container">
                <div class="row">
                  <div class="col">

                    <h1 class="poppins v2-font-35 bold dark-text-2 mb-10 tools-3-heading v2-mt-2">
                      <strong class="">Wallet Compensation Plan</strong>
                    </h1>
                    
                    <div class="leaderboard-body wcp-body" id="wcp-body">
                        <div class="leaderboard-tbl-wrap">
                            <div class="leaderboard-tbl-header">
                                <div>
                                    Country Name
                                </div>
                                <div>
                                    Merchant Wallet Bounty $
                                </div>
                                <div>
                                    User Wallet Bounty $
                                </div>
                            </div>
                            {/* <!-- end class leaderboard-tbl-header --> */}
                            
                            <div class="leaderboard-tbl-body" id="wcp-tbl-body">
                                {data && (
                                    data.map((item, index) => (
                                        <div className="leaderboard-tbl-body-row" key={item.id}>
                                            <div data-label="Country Name">{`${index + 1}. ${item.country_name}`}</div>
                                            <div data-label="Merchant Wallet Bounty $">{`$${item.merchant_wallet_bounty}`}</div>
                                            <div data-label="User Wallet Bounty $">{`$${item.user_wallet_bounty}`}</div>
                                        </div>
                                    ))
                                )}
                            </div>{/* <!-- end class leaderboard-tbl-body --> */}
                        </div>{/* <!-- end class leaderboard-tbl-wrap --> */}
                    </div>

                  </div>
                </div>
              </div>
            </section>
            
            <Footer />
        </>
    );
  }
}

export default WalletCompensationPlan;